import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Table } from "antd";
import moment from "moment";
import OrderTrackModal from "../../../Seller/Modal/OrderTrackModal/OrderTrackModal";
import { orderManagementActions } from "../../../../modules/SellerDashboard/OrderManagement/ducks";
import AlgoliaSearch from "../../../Common/Algolia/AlgoliaSearch";
import "./OrderList.less";
import { DEFAULT_CURRENCY } from "../../../../constants";
import { contactSellerHandler } from "services/message.service";
import { chatActions } from "../../../../modules/ChatPage/ducks";
class OrderList extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    visible: false,
    confirmLoading: false,
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };

  openDisputeHandler = (record) => {
    var itemObj = record;
    var itemString = JSON.stringify(itemObj);
    // setter
    localStorage.setItem("rcr_item", itemString);
    this.props.history.push("/seller/dashboard/resolution-center/open-case");
  };

  render() {
    let { hits, match } = this.props;
    const currentRoute = match.url;

    const columns = [
      {
        title: "Order ID",
        width: "140px",
        render: (text, record) => (
          <div style={{ textAlign: "center" }}>
            <img
              width="100px"
              alt="avatar"
              src={record.product && record.product.img}
            />
          </div>
        ),
      },
      {
        title: "Product",
        width: "32%",
        className: "pdct-colm",

        render: (text, record) => {
          return (
            <div>
              <div>
                <b>Name : </b>
                {record.product && record.product.name}
              </div>
              <br />
              <div>
                <b>Order ID : </b>
                {record.objectID}
              </div>
              <div>
                <b>Create : </b>
                {moment(record.created_at).format("LLLL")}
              </div>
              <div>
                <b>Update : </b>
                {moment(record.modified_at).format("LLLL")}
              </div>

              <br />
              <Button
                block
                className="gx-btn-outline-danger mb-0"
                onClick={() => this.openDisputeHandler(record)}
              >
                <i className="fad fa-exclamation-circle mr-1"></i>
                Open Dispute
              </Button>
            </div>
          );
        },
      },
      {
        title: "Shipping Details",
        render: (text, record) => {
          return (
            <div>
              <b>Status : </b>
              <span
                className={
                  record.status === "In Progress"
                    ? "inprogs-clr"
                    : record.status === "Paid"
                    ? "paid-clr"
                    : record.status === "Cancelled"
                    ? "cancle-clr"
                    : record.status === "Delivered"
                    ? "fnshed-clr"
                    : record.status === "Returned"
                    ? "retn-clr"
                    : "shpp-clr"
                }
              >
                {record.status}
              </span>
              <br />
              <br />
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: "72px", verticalAlign: "top" }}>
                      <b>Address : </b>
                    </td>
                    <td>
                      {`${record?.shipping?.address?.first_name} ${record?.shipping?.address?.last_name}`},
                      <br/>
                      {record?.shipping?.address?.address1},
                      <br />
                      {record?.shipping?.address?.address2  } 
                      {record?.shipping?.address?.address2 ? <br /> : null } 
                      
                      {record?.shipping?.address?.city},
                      <br />
                      {record?.shipping?.address?.state},
                      <br />
                      {record?.shipping?.address?.country},
                      <br />
                      {record?.shipping?.address?.zip}
                         <br />
                         {record?.shipping?.address?.phone  } 
                    </td>
                  </tr>
                </tbody>
              </table>
              <OrderTrackModal
                record={record}
                shippingAddress={record.shipping}
              />
              <Button
                block
                className="gx-btn-outline-info mb-0 contact-buyer-btn-top-margin"
                onClick={() =>
                  contactSellerHandler(
                    record?.buyer_id,
                    currentRoute,
                    this.props.chatActions.selectedSellerHandler
                  )
                }
              >
                <i className="fad fa-comments mr-2"></i>
                Contact Buyer
              </Button>
            </div>
          );
        },
      },
      {
        title: "Transaction",
        className: "pdct-colm",
        width: "280px",

        render: (text, record) => {
          
          return (
            <div>
              <b>Status : </b>
              {record.transaction && record.transaction.status && (
                <span
                  className={
                    record.transaction.status === "Pending"
                      ? "inprogs-clr"
                      : record.transaction.status === "Failed"
                      ? "cancle-clr"
                      : record.transaction.status === "Finished"
                      ? "fnshed-clr"
                      : "shpp-clr"
                  }
                >
                  {record.transaction.status}
                </span>
              )}{" "}
              <br />
              <br />
              <b>Method : </b>
              {record.transaction && record.transaction.payment_type}
              <br />
              <br />
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <b>Cost :</b>
                    </td>
                    <td style={{ textAlign: "end" }}>
                      Prodct Price :{" "}
                      <span className="text-clr-org">
                        {`${DEFAULT_CURRENCY}
                      ${
                        record.transaction && record.transaction.product_price
                      }`}
                      </span>
                      <br /> Shipping Cost :{" "}
                      <span className="text-clr-org">
                        {`${DEFAULT_CURRENCY}
                      ${
                        record.transaction && record.transaction.shipping_cost
                      }`}
                      </span>
                      <hr className="my-1" />
                      <b>
                        Total :{" "}
                        <span className="text-clr-org">
                          {`${DEFAULT_CURRENCY}
                        ${
                          record.transaction && record.transaction.grand_total
                        }`}
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        },
      },
    ];

    return (
      <div className="mb-2 mt-3">
        <div className="table-operations">
          <AlgoliaSearch customStyle={{ width: "auto" }} />
        </div>
        <Table
          className=""
          columns={columns}
          dataSource={hits}
          onChange={this.handleChange}
          pagination={false}
          bordered
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ordersCount: state.OrderManagement.ordersCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderManagementActions: bindActionCreators(
      orderManagementActions,
      dispatch
    ),
    chatActions: bindActionCreators(chatActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderList)
);
