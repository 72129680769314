import React from "react";
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../../constants/ThemeSetting";
import IntlMessages from "util/IntlMessages";
import { sidebarSettings } from "constants/Utilities";
import { userRoles } from "../../../constants/Utilities";

const SubMenu = Menu.SubMenu;

const SidebarContent = (props) => {
  const { userJson } = props;
  const { url } = props.match;
  const { themeType } = sidebarSettings;

  let selectedKeys = url;
  const defaultOpenKeys = selectedKeys.split("/")[3];
  if (defaultOpenKeys === "profile") {
    selectedKeys = "/seller/dashboard/profile"
  } else if (url.includes('/seller/dashboard/productManagement/productList')) {
    selectedKeys = "/seller/dashboard/productManagement/productList"
  } else if (url.includes('/seller/dashboard/resolution-cente')) {
    selectedKeys = "/seller/dashboard/resolution-center"
  }
  return (
    <div style={{ height: "100%" }}>
      <SidebarLogo />

      <div className="gx-sidebar-content mt-5">
        {/* <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile userJson={userJson} />
          <AppsNavigation />
        </div> */}

        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "lite"}
            mode="inline"
            style={{ height: "100% !important" }}
          >
            {/* Dashboard */}
            <Menu.Item key="/dashboard">
              <Link to="/dashboard">
                {/* <i className="icon icon-dasbhoard" style={{ verticalAlign: "middle"}}/> */}
                <i className="fad fa-analytics icon"></i>
                {/* <IntlMessages id="sidebar.productDetail" /> */}
                Dashboard
              </Link>
            </Menu.Item>

            {/* Product Management */}
            <SubMenu
              key="productManagement"
              title={
                <span>
                  <i
                    className="fad fa-boxes-alt icon"
                    style={{ height: "unset" }}
                  ></i>
                  <span>
                    <IntlMessages id="sidebar.productManagement" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="/seller/dashboard/productManagement/productList">
                <Link to="/seller/dashboard/productManagement/productList/all">
                  <i className="fad fa-box-alt icon"></i>
                  {/* <IntlMessages id="sidebar.product" /> */}
                  Product Management
                </Link>
              </Menu.Item>
              <Menu.Item key="/seller/dashboard/productManagement/product/add">
                <Link to="/seller/dashboard/productManagement/product/add">
                  <i className="fad fa-plus-square icon"></i>
                  {/* <IntlMessages id="sidebar.resumeProduct" /> */}
                  Add Product
                </Link>
              </Menu.Item>
              <Menu.Item key="/seller/dashboard/productManagement/qnaManagement">
                <Link to="/seller/dashboard/productManagement/qnaManagement">
                  <i className="fad fa-question-circle icon"></i>
                  {/* <IntlMessages id="sidebar.siteVisitor" /> */}
                  Q&A Management
                </Link>
              </Menu.Item>
            </SubMenu>

            {/* Order Management */}
            <Menu.Item key="/seller/dashboard/orderManagement/all">
              <Link to="/seller/dashboard/orderManagement/all">
                <i className="fad fa-cart-arrow-down icon"></i>
                {/* <IntlMessages id="sidebar.productDetail" /> */}
                Order Management
              </Link>
            </Menu.Item>

            {/* Transaction Overview */}
            <Menu.Item key="/seller/dashboard/finance/transaction-overview">
              <Link to="/seller/dashboard/finance/transaction-overview">
                <i className="fad fa-exchange icon"></i>
                {/* <IntlMessages id="sidebar.productDetail" /> */}
                Transaction Overview
              </Link>
            </Menu.Item>

            {/* Feedback Management */}
            <SubMenu
              key="productReview"
              title={
                <span>
                  <i
                    className="fad fa-star-half icon"
                    style={{ height: "unset" }}
                  ></i>
                  <span>
                    {/* <IntlMessages id="sidebar.orderManagement" /> */}
                    Review Management
                  </span>
                </span>
              }
            >
              <Menu.Item key="/seller/dashboard/productReview">
                <Link to="/seller/dashboard/productReview">
                  <i className="fad fa-list icon"></i>
                  {/* <IntlMessages id="sidebar.productDetail" /> */}
                  Product Review
                </Link>
              </Menu.Item>
              {/* <Menu.Item>
                <Link to="/seller/dashboard/sellerReview">
                  <i className="icon icon-widgets" />
                  Seller Review
                </Link>
              </Menu.Item> */}
            </SubMenu>

            {/* Account Management */}
            <SubMenu
              key="profile"
              title={
                <span>
                  <i
                    className="fad fa-user-circle icon"
                    style={{ height: "unset" }}
                  ></i>
                  <span>
                    <IntlMessages id="sidebar.accountPage" />
                  </span>
                </span>
              }
            >
              <Menu.Item key={"/seller/dashboard/profile"}>
                <Link to="/seller/dashboard/profile/general/selleraccount">
                  <i className="fad fa-id-card icon"></i>
                  {/* <IntlMessages id="sidebar.personalInformation" /> */}
                  Profile
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="/seller/dashboard/profile/account-settings">
                <Link to="/seller/dashboard/profile/account-settings">
                  <i className="icon icon-setting" />
                  <IntlMessages id="sidebar.feedback" />
                  Account Settings
                </Link>
              </Menu.Item> */}
            </SubMenu>

            {/* Report Management */}
            {userJson && userJson.role !== userRoles.Moderator && (
              <SubMenu
                key="report"
                title={
                  <span>
                    <i
                      className="fad fa-layer-group icon"
                      style={{ height: "unset" }}
                    ></i>
                    <span>
                      <IntlMessages id="sidebar.reportManagment" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="/seller/dashboard/report/daily">
                  <Link to="/seller/dashboard/report/daily">
                    <i className="fad fa-file-excel icon"></i>
                    <IntlMessages id="sidebar.dialyReport" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/seller/dashboard/report/weekly">
                  <Link to="/seller/dashboard/report/weekly">
                    <i className="fad fa-file-excel icon"></i>
                    {/* <IntlMessages id="sidebar.dialyReport" /> */}
                    Weekly Report
                  </Link>
                </Menu.Item>
                <Menu.Item key="/seller/dashboard/report/monthly">
                  <Link to="/seller/dashboard/report/monthly">
                    <i className="fad fa-file-excel icon"></i>
                    {/* <IntlMessages id="sidebar.dialyReport" /> */}
                    Monthly Report
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="/seller/dashboard/report/quarterly">
                <Link to="/seller/dashboard/report/quarterly">
                  <i className="fad fa-file-excel icon"></i>
                  Quarterly Report
                </Link>
              </Menu.Item> */}
                <Menu.Item key="/seller/dashboard/report/yearly">
                  <Link to="/seller/dashboard/report/yearly">
                    <i className="fad fa-file-excel icon"></i>
                    {/* <IntlMessages id="sidebar.dialyReport" /> */}
                    Yearly Report
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}

            {/* Message Center */}
            <Menu.Item key="/seller/dashboard/message-center">
              <Link to="/seller/dashboard/message-center">
                <i className="fad fa-comments-alt icon"></i>
                {/* <IntlMessages id="sidebar.productDetail" /> */}
                Message Center
              </Link>
            </Menu.Item>

            {/* Resolution Center */}
            <Menu.Item key="/seller/dashboard/resolution-center">
              <Link to="/seller/dashboard/resolution-center/all">
                <i className="fad fa-house-user icon"></i>
                {/* <IntlMessages id="sidebar.productDetail" /> */}
                Resolution Center
              </Link>
            </Menu.Item>

            {/*User Management */}
            {userJson &&
              (userJson.role === userRoles.Admin ||
                userJson.role === userRoles.Moderator) && (
                <Menu.Item key="/seller/dashboard/userManagement">
                  <Link to="/seller/dashboard/userManagement/seller">
                    <i className="fad fa-users icon"></i>
                    {/* <IntlMessages id="sidebar.productDetail" /> */}
                    User Management
                  </Link>
                </Menu.Item>
              )}
          </Menu>
        </CustomScrollbars>
      </div>
    </div>
  );
};

SidebarContent.propTypes = {};
export default withRouter(SidebarContent);
