import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Form, Col, Row, Input } from "antd";
import { reduxForm, Field, getFormValues } from "redux-form";
import { DEFAULT_CURRENCY } from "../../../../constants";
import { makeField } from "../../../Common/Controls/MakeField";
import {
  FormItemLayout,
  FormItemLayout2,
} from "../../../../constants/Utilities";
import ExtraInfo from "../../../../modules/SellerDashboard/ProductManagement/AddPoduct/ExtraInfo";
import { extraInfoDetails } from "../../../../modules/SellerDashboard/ProductManagement/AddPoduct/ExtraInfoDetails";
import { productManagementActions } from "../../../../modules/SellerDashboard/ProductManagement/ducks";
import ProductQuickVariationsTable from "./ProductQuickVariationsTable";

const FormItem = Form.Item;
const AInputField = makeField(Input);

class ProductQuickEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productVariations: [],
      isEnableProductVariable: false,
    };
  }
  componentDidMount() {
    const {
      variations,
      variations_available,
    } = this.props.data;

    //variation array here
    var productVariations = variations;

    this.setState({
      productVariations: productVariations,
      isEnableProductVariable: variations_available,
    });
  }

  //product variations table add row
  handleAddRow = () => {
    const item = {
      name: "",
      mobile: "",
    };
    this.setState({
      productVariations: [...this.state.productVariations, item],
    });
  };

  //product variation table remove row
  handleRemoveSpecificRow = (idx) => {
    let productVariations = [...this.state.productVariations];
    productVariations.splice(idx, 1);

    const { fieldValues } = this.props;
    Object.keys(fieldValues).forEach((variation) => {
      const var_arr = variation.split(":");
      if (var_arr[0] === "var") {
        let [, i, label] = var_arr;
        if (variation.startsWith(`var:${idx}`)) delete fieldValues[variation];
        else if (+i > idx) {
          delete Object.assign(fieldValues, {
            [`var:${i - 1}:${label}`]: fieldValues[`var:${i}:${label}`],
          })[`var:${i}:${label}`];
        }
      }
    });

    this.setState({ productVariations });
  };

  handleSubmit = (values) => {
    const { isEnableProductVariable } = this.state;
    const { fieldValues } = this.props
    var variation_types = this.props.data?.variation_types;

    // variations structure space here
    const variations_mapped = [];
    Object.keys(fieldValues).forEach((variation) => {
      const var_arr = variation.split(":");
      if (var_arr[0] === "var") {
        let [, idx, label] = var_arr;
        idx = parseInt(idx);
        variations_mapped[idx] = variations_mapped[idx] || {};
        variations_mapped[idx][label] = fieldValues[variation];
      }
    });

    /**
     * final product variations -------------------
     * IF user change the attribute name then remove the olders name
     * compare with variation types
     */
    let finalVariations = variations_mapped.map((v) => {
      Object.keys(v).forEach((key) => {
        if (!variation_types.includes(key) && !["qty", "price"].includes(key))
          delete v[key];
      });
      return v;
    });

    let updatedProductDto = {
      // ...this.props.data,
      name: this.props.data?.name,
      // highlight:values?.highlight,
      description: this.props.data?.description,
      price: isEnableProductVariable ? undefined : values?.price,
      qty: isEnableProductVariable ? undefined : values?.productStock,
      brand: this.props.data?.brand,
      discount_percentage: values?.discount,
      variation_types: this.props.data?.variation_types,
      variations: finalVariations || []
    };

    this.props.productManagementActions.quickUpdateProduct({
      id: this.props.data.id,
      productDto: updatedProductDto,
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { isEnableProductVariable, productVariations } = this.state;

    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          {!isEnableProductVariable && (
            <FormItem
              {...FormItemLayout}
              label="Price"
              required={!isEnableProductVariable}
            >
              <Field
                formItemLayout={FormItemLayout2}
                name="price"
                component={AInputField}
                placeholder="Price"
                hasFeedback
                disabled={isEnableProductVariable}
                addonBefore={DEFAULT_CURRENCY}
                type="number"
                onWheel={(e) => e.target.blur()}
              // min="0"
              // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  bottom: "-37px",
                }}
              >
                <ExtraInfo title={extraInfoDetails.packagePrice} />
              </span>
            </FormItem>
          )}

          {!isEnableProductVariable && (
            <FormItem
              {...FormItemLayout}
              label="Availability"
              required={!isEnableProductVariable}
            >
              <Field
                formItemLayout={FormItemLayout2}
                name="productStock"
                component={AInputField}
                placeholder="Availability"
                type="text"
                normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                hasFeedback
                disabled={isEnableProductVariable}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  bottom: "-37px",
                }}
              >
                <ExtraInfo title={extraInfoDetails.packageAvailability} />
              </span>
            </FormItem>
          )}

          <React.Fragment>
            {isEnableProductVariable && (
              <ProductQuickVariationsTable
                isEnableProductVariable={true}
                productVariations={productVariations}
                variations={this.props.data.variation_types}
                handleAddRow={this.handleAddRow}
                handleRemoveSpecificRow={(idx) =>
                  this.handleRemoveSpecificRow(idx)
                }
              />
            )}
          </React.Fragment>

          <FormItem {...FormItemLayout} label="Discount" required={false}>
            <Field
              formItemLayout={FormItemLayout2}
              type="number"
              // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
              name="discount"
              component={AInputField}
              placeholder="Discount"
              hasFeedback
              addonAfter="%"
              onWheel={(e) => e.target.blur()}
              min="0"
            />
            <span
              style={{
                position: "absolute",
                right: "10px",
                bottom: "-37px",
              }}
            >
              <ExtraInfo title={extraInfoDetails.packageDiscount} />
            </span>
          </FormItem>

          <Row>
            <Col span={24} style={{ padding: "0px" }}>
              <div className="">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                // className="btn new-btn-clr"
                >
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};

  // if (!values.cardNumber) {
  //   errors.cardNumber = "Card number is required";
  // }
  return errors;
};

function mapStateToProps(state) {
  return {
    createProduct: state.ProductManagement.createProduct,
    changeProductActiveStatus:
      state.ProductManagement.changeProductActiveStatus,
    deleteProduct: state.ProductManagement.deleteProduct,
    fieldValues: getFormValues("product_quick_update")(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
  };
}

// export default ProductQuickEditForm
export default withRouter(
  reduxForm({
    form: "product_quick_update",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ProductQuickEditForm))
);
