import React from "react";
import { createLogic } from "redux-logic";
import { NotificationManager } from "react-notifications";
import { reset } from "redux-form";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../../util/EndPoints";
import API from "../../../../util/HTTPClient";
import history from "../../../../_helpers/history";
import IntlMessages from "util/IntlMessages";
import { SUCCESS_TOASTER_TIMEOUT } from "../../../../constants";

// *********************************************************//
const getProductCount = createLogic({
  type: types.GET_PRODUCTS_COUNT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_PRODUCT_COUNT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getProductCountSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getProductCountFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const createProduct = createLogic({
  type: types.CREATE_PRODUCT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.CRUD_PRODUCT, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.createProductSuccess(data));
        setTimeout(function () {
          NotificationManager.success(
            data.message,
            "Success",
            SUCCESS_TOASTER_TIMEOUT
          );
          dispatch(reset("AddPoduct_form")); // requires form name
        }, 3000);
        history.push("/seller/dashboard/productManagement/productList/all");
      })

      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.createProductFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const getProduct = createLogic({
  type: types.GET_PRODUCT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.CRUD_PRODUCT + `/${action.payload}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getProductSuccess(data));
        //update product image gallery
        dispatch(actions.handleProductImages(data.data.images));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getProductFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const updateProduct = createLogic({
  type: types.UPDATE_PRODUCT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(
      endPoints.CRUD_PRODUCT + `/${action.payload.id}`,
      action.payload.productDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.updateProductSuccess(data));
        setTimeout(function () {
          NotificationManager.success(
            data.message,
            "Success",
            SUCCESS_TOASTER_TIMEOUT
          );
          dispatch(reset("AddPoduct_form")); // requires form name
        }, 3000);
        window.location.href =
          "/seller/dashboard/productManagement/productList/all";
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.updateProductFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const quickUpdateProduct = createLogic({
  type: types.QUICK_UPDATE_PRODUCT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(
      endPoints.QUICK_PRODUCT + `/${action.payload.id}`,
      action.payload.productDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.quickUpdateProductSuccess(data));
          NotificationManager.success(
            data.message,
            "Success",
            SUCCESS_TOASTER_TIMEOUT
          );
          // dispatch(reset("product_quick_update")); // requires form name
        // window.location.href =
        //   "/seller/dashboard/productManagement/productList/all";
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.quickUpdateProductFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const deleteProduct = createLogic({
  type: types.DELETE_PRODUCT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Delete(endPoints.CRUD_PRODUCT + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        const successDto = {
          ...data,
          id: action.payload.id,
        };
        dispatch(actions.deleteProductSuccess(successDto));
        action.payload.callback(action.payload.id);
        dispatch(actions.getProductCount());
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        dispatch(
          actions.deleteProductFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const changeProductActiveStatus = createLogic({
  type: types.CHANGE_PRODUCT_ACTIVE_STATUS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Patch(endPoints.CRUD_PRODUCT + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        let message = data.message || "Product status changed";
        NotificationManager.success(
          message,
          "Success",
          SUCCESS_TOASTER_TIMEOUT
        );
        const successDto = {
          ...data,
          id: action.payload.id,
        };

        dispatch(actions.changeProductActiveStatusSuccess(successDto));
        action.payload.callback(action.payload.id);
        dispatch(actions.getProductCount());
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.changeProductActiveStatusFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const getProductCategories = createLogic({
  type: types.GET_PRODUCT_CATEGORIES,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_PRODUCT_CATEGORIES)
      .then((resp) => resp.data)
      .then((data) => {
        if (action.payload.mode === "UPDATE") {
          dispatch(actions.getProduct(action.payload.id));
        }
        dispatch(actions.getProductCategoriesSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getProductCategoriesFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *********************************************************//
const replyQuestion = createLogic({
  type: types.REPLY_QUESTION,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(endPoints.REPLY_QUESTION)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getProductCategoriesSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getProductCategoriesFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// ***************************REPLY_TO_QNA******************************//
const replyToQnA = createLogic({
  type: types.REPLY_TO_QNA,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(
      endPoints.PRODUCT_FAQS + `/${action.payload.id}`,
      action.payload.answerDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        NotificationManager.success(
          data.message,
          "Success",
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.replyToQnASuccess(data));
        action.payload.callback(action.payload.id);
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.replyToQnAFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// *************************DELETE_QNA********************************//
const deleteQnA = createLogic({
  type: types.DELETE_QNA,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(endPoints.PRODUCT_FAQS)
      .then((resp) => resp.data)
      .then((data) => {
        NotificationManager.success(
          data.message,
          "Success",
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.deleteQnASuccess(data));
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.deleteQnAFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
// *********************************************************//
// ***********************DELETE_QNA_REPLY**********************************//
const deleteQnAReply = createLogic({
  type: types.DELETE_QNA_REPLY,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(endPoints.PRODUCT_FAQS)
      .then((resp) => resp.data)
      .then((data) => {
        NotificationManager.success(
          data.message,
          "Success",
          SUCCESS_TOASTER_TIMEOUT
        );
        dispatch(actions.deleteQnAReplySuccess(data));
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.deleteQnAReplyFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});
//******************GET_SHIPPING_METHOD*********************//

const getShippingMethod = createLogic({
  type: types.GET_SHIPPING_METHOD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.SHIPPING_METHOD)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getShippingMethodSuccess(data));
      })
      .catch((err) => {
        // var { data } = err.response;
        // var errMessage = data && data.message;
        // var errorMessage = "Failed to get regions";
        // if (err && err.code === "ECONNABORTED") {
        //   errorMessage = "Please check your internet connection.";
        // }
        dispatch(
          actions.getShippingMethodFail({
            title: "Error!",
            // message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

// *********************************************************//

export default [
  getProductCount,
  createProduct,
  getProduct,
  updateProduct,
  quickUpdateProduct,
  deleteProduct,
  changeProductActiveStatus,
  getProductCategories,
  replyQuestion,
  replyToQnA,
  deleteQnA,
  deleteQnAReply,
  getShippingMethod,
];
