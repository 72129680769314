import React, { useEffect } from "react";
import { Cascader } from "antd";
import { GetDeliveryAreasByDistrict } from "../../../../firebase/functions";
import { useDispatch, useSelector } from "react-redux";
import types from "./../../../../modules/DomesticShipping/ducks/types";
import cartActions from "../../../../modules/Cart/ducks/actions";

async function getDeliveryAreasForDistrict(
  selectedOptions,
  domesticLocationsOptions,
  setDomesticLocationsOptions
) {
  const targetOption = selectedOptions[0];
  targetOption.loading = true;

  const deliveryAreas = await GetDeliveryAreasByDistrict(targetOption.label);

  targetOption.children = deliveryAreas.map((deliveryArea) => {
    return {
      label: deliveryArea.city,
      value: deliveryArea,
    };
  });

  targetOption.loading = false;
  setDomesticLocationsOptions([...domesticLocationsOptions]);
}

function LocationPicker({
  locationOnChangeHandler,
  name,
  value,
  required = false,
}) {
  const dispatch = useDispatch();
  const Domesticshipping = useSelector((state) => state.Domesticshipping);

  const [domesticLocationsOptions, setDomesticLocationsOptions] =
    React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState({});
  const [showValidateMessage, setShowValidateMessage] = React.useState(false);

  useEffect(() => {
    const locationData = Domesticshipping?.domesticLocations?.data || [];
    if (!locationData.length) {
      dispatch({
        type: types.GET_DOMESTIC_LOCATIONS,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Domesticshipping?.domesticLocations?.loading) {
      setDomesticLocationsOptions(
        Domesticshipping?.domesticLocations?.data || []
      );
    }
  }, [Domesticshipping]);

  useEffect(() => {
    // if (Object.keys(selectedValue).length !== 0) {
    //   // dispatch(cartActions.selectedDomesticShippingLocation(selectedValue));

    //   if (selectedValue.length) {
    //     setShowValidateMessage(false);
    //     locationOnChangeHandler(selectedValue?.[1] || {});
    //   }
    //   //  else {
    //   //   locationOnChangeHandler({});
    //   // }

    //   var selectedLocationString = JSON.stringify(selectedValue?.[1] || {});
    //   // setter
    //   localStorage.setItem("selectedLocation", selectedLocationString);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }
    selectedValueHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const selectedValueHandler = () => {
    if (Object.keys(selectedValue).length !== 0) {
      // debugger;
      dispatch(
        cartActions.selectedDomesticShippingLocation(selectedValue?.[1] || {})
      );

      if (selectedValue.length) {
        setShowValidateMessage(false);
        locationOnChangeHandler(selectedValue?.[1] || {});
      }
      //  else {
      //   locationOnChangeHandler({});
      // }

      var selectedLocationString = JSON.stringify(selectedValue?.[1] || {});
      // setter
      localStorage.setItem("selectedLocation", selectedLocationString);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  return (
    <>
      <div
        className={`ant-form-item-control ${
          !value && showValidateMessage && required ? "has-error" : ""
        }`}
      >
        <Cascader
          name={name}
          options={domesticLocationsOptions}
          onChange={(value, _) => setSelectedValue(value)}
          placeholder={value || "Please Select Location"}
          loadData={(selectedOptions) =>
            getDeliveryAreasForDistrict(
              selectedOptions,
              domesticLocationsOptions,
              setDomesticLocationsOptions
            )
          }
          onPopupVisibleChange={() => setShowValidateMessage(true)}
        />
        {!value && showValidateMessage && required && (
          <div className="ant-form-explain">Value required</div>
        )}
      </div>
    </>
  );
}

export default LocationPicker;
