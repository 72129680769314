import React from "react";
import { Layout } from "antd";
import {
  Panel,
  MenuSelect,
  Configure,
  connectHits,
  connectStateResults,
  InstantSearch,
  Pagination,
} from "react-instantsearch-dom";
import "instantsearch.css/themes/algolia.css";
import AlgoliaFooter from "../../../Common/AlgoliaFooter/AlgoliaFooter";
import ProductList from "./ProductList";
import { initAlgoliaClient } from "../../../../services/algolia.service";
import AlgoliaSearch from "../../../Common/Algolia/AlgoliaSearch";

const { Content } = Layout;

const ProductContainer = (props) => (
  <div className="" style={{ overflow: "auto" }}>
    <InstantSearch
      className="gx-main-content"
      indexName={props.index}
      searchState={props.searchState}
      createURL={props.createURL}
      searchClient={initAlgoliaClient(props.apiKey)}
      onSearchStateChange={props.onSearchStateChange}
    >
      <Configure hitsPerPage={10} />

      <Layout className="gx-algolia-layout-has-sider">
        <div className="row">
          <div className="col-6" style={{ width: '40%', margin: '20px 0' }}>
            <Panel header={<span>Seller</span>}>
              <MenuSelect 
                  className="gx-algolia-refinementList"
                  attribute="seller_name"
                  operator="or"
                />
            </Panel>
          </div>

          <div className="table-operations col-6" style={{margin: '20px 0'}}>
            <Panel header={<span>Search</span>}>
              <div style={{border: '1px solid #c4c8d8',  width: "100%", height: 38, padding: '0 10px'}}>
                <AlgoliaSearch customStyle={{ width: "auto", height: "auto"}} />
              </div>
            </Panel>
          </div>
        </div>
        <Content>
          <CustomResults />
        </Content>
        <AlgoliaFooter>
          <Pagination showLast={true} />
        </AlgoliaFooter>
      </Layout>
    </InstantSearch>
  </div>
);

const CustomResults = connectStateResults(({ searchState, searchResult }) => {
  if (searchResult && searchResult.nbHits === 0) {
    return (
      <div className="gx-algolia-content-inner">
        <div className="gx-algolia-no-results">
          No results found matching{" "}
          <span className="gx-algolia-query">{searchState.query}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="gx-algolia-content-inner">
        <ConnectedProducts />
      </div>
    );
  }
});

const ConnectedProducts = connectHits(ProductList);

export default ProductContainer;
