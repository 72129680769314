import React from "react";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { Button, Table } from "antd";
import "./ResolutionList.less";
import { GetCurrentUserID } from "../../../services/auth.service";
import { DEFAULT_CURRENCY } from "../../../constants";
class ResolutionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      isDashboard: false,
      currentUserID: null,
    };
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    var isDashboard = false;
    this.getCurrentUser();
    if (pathname.includes("/seller/dashboard/resolution-center")) {
      isDashboard = true;
    }
    this.setState({
      isDashboard,
    });
  }

  getCurrentUser = async () => {
    let currentUserID = await GetCurrentUserID();
    this.setState({
      currentUserID: currentUserID,
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  onRow = (e) => {
    
  };

  replyHandler = (record) => {
    // debugger;
    var id = record && record.objectID;
    this.props.history.push(`/resolution/case/${id}`);
    // this.props.history.push(`/`);
  };

  caseHandler = (record) => {
    var { isDashboard } = this.state;

    if (isDashboard) {
      this.props.history.push(
        `/seller/dashboard/resolution-center/case/${record.objectID}`
      );
    } else {
      this.props.history.push(`/resolution/case/${record.objectID}`);
    }
  };

  render() {
    let { hits } = this.props;
    let { currentUserID } = this.state;
    const columns = [
      {
        title: "Product",
        width: "110px",
        render: (text, record) => (
          <img
            alt="avatar"
            src={(record.product && record.product.image) || record.product.img}
            // src={
            //   currentUserObj && currentUserObj.photoURL
            //     ? currentUserObj.photoURL
            //     : require("assets/images/avatar/domnic-harris.png")
            // }
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          />
        ),
      },
      {
        title: "Name & Price",
        width: "30%",
        render: (text, record) => (
          <div>
            <div>
              <b>Name : </b>
              <Link className="crd-fnt-jr" to={`/product/${record.product.id}`}>
                {record && record.product && record.product.name}
              </Link>
            </div>
            <div>
              <b>Price : </b>
              <span style={{ color: "#ff6900" }}>
                {`${DEFAULT_CURRENCY}${record?.product?.price}`}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: "Conversation",
        width: "30%",
        render: (text, record) => {
          let lastMessage =
            record &&
            record.conversation &&
            record.conversation[record.conversation.length - 1];
          return (
            <div>
              <div>
                <b>Reason : </b> {record && record.reason}
              </div>
              <div>
                <b>Status : </b>
                {record.status === "Open" ? (
                  <span className="fnshed-clr">Open</span>
                ) : record.status === "Closed" ? (
                  <span className="deliv-clr">Closed</span>
                ) : (
                  <span className="cancle-clr">Reviewing by Admin</span>
                )}
              </div>
              <div className="jr-text-truncate-rc">
                <b>Last message : </b>
                {lastMessage && lastMessage.from === currentUserID ? (
                  <span style={{ color: "#13c2c2" }}>You {">"} </span>
                ) : (
                  <span style={{ color: "#2f54eb" }}>
                    {lastMessage.sender_name}
                    {" > "}
                  </span>
                )}
                {lastMessage && lastMessage.message}
              </div>
              <div>
                <b>Last Update : </b>
                {moment(
                  record &&
                    record.conversation &&
                    record.conversation[record.conversation.length - 1]
                      .created_at
                ).format("LLL")}
              </div>
            </div>
          );
        },
      },
      {
        title: "Actions",
        fixed: "right",
        width: "150px",
        render: (text, record) => (
          <React.Fragment>
            <Button
              className="gx-btn-outline-primary"
              onClick={() => this.caseHandler(record)}
              // style={{ marginRight: "10px", marginBottom: "0" }}
            >
              {record.status === "Closed" ? (
                <span>
                  <i className="fad fa-eye mr-1"></i> View
                </span>
              ) : (
                <span>
                  <i className="fad fa-reply mr-1"></i> Reply
                </span>
              )}
            </Button>
            {/* <Link to={`case/${record.objectID}`}>Reply</Link> */}
            <br />

            {/* <Popconfirm
              title={`Sure to Delete ?`}
              onConfirm={() => this.userDeleteHandler(record)}
            >
              <Button>Send Admin</Button>
            </Popconfirm> */}
          </React.Fragment>
        ),
      },
    ];

    return (
      <Table
        className="resol-tbl-tag"
        style={{ minWidth: "500px" }}
        columns={columns}
        dataSource={hits}
        onChange={this.handleChange}
        pagination={false}
        scroll={{ x: 1200 }}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => {
        //       this.props.history.push(
        //         `/seller/dashboard/product/update/${record && record.id}`
        //       );
        //     }, // click row
        //   };
        // }}
      />
    );
  }
}

export default withRouter(ResolutionList);
