import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Tabs, Input, Spin, Form, Select, Descriptions } from "antd";
import { orderManagementActions } from "../../../../modules/SellerDashboard/OrderManagement/ducks";
import { makeField } from "../../../Common/Controls/MakeField";
import { cartActions } from "../../../../modules/Cart/ducks";
import {
  pickupVehicleTypes,
  TailFormItemLayout,
} from "../../../../constants/Utilities";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const { TabPane } = Tabs;

const FormItem = Form.Item;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class DomesticOrderTrackModelBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      rating_value: 0,
      content: "",
      order_id: props.orderID,
      select_shipping: "select your shipping method",
      shippingList: props.shippingAddressList,
      record: props.record,
      shippingAddress: props.shippingAddress,
    };
  }
  componentDidMount() {
    const { record } = this.props;
    this.props.initialize({ vehicleType: "Bike" });
    this.props.cartActions.getShippingDetails({ id: record.objectID });
  }

  handleChange = (e) => {
    this.setState({ review: e.target.value });
  };

  handleSubmit = (values) => {
    const { record } = this.props;
    var { vehicleType, barcodeNumber } = values;

    let orderPlaceDto = {
      order_id: record?.objectID,
      vehicle_type: vehicleType,
      barcode_number: barcodeNumber,
    };
    this.props.cartActions.deliveryOrderShippingHandler({
      orderPlaceDto: orderPlaceDto,
      callback: this.props.onCancel,
    });
  };

  downloadHandler = (label) => {
    window.open(label, "_blank");
  };

  download = (e) => {
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.download = "image.jpg";
          // link.click();
        });
      })
      .catch((err) => {
        
      });
  };

  render() {
    const { isUpdate, shippingDetails } = this.props;
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      deliveryOrderShippingHandler,
    } = this.props;

    return (
      <React.Fragment>
        <Spin
          spinning={
            shippingDetails.pending || deliveryOrderShippingHandler.pending
          }
        >
          <div>
            <Tabs>
              <TabPane tab="Add Tracking" key="2">
                <p>
                  If you taking shipping service on your own and that service
                  provide a tracking feature. Here you can put relevant details
                  regarding that tracking. These details will show in the
                  buyer's dashboard. So your buyer will up to date with order
                  status
                </p>

                <div>
                  <Descriptions
                    title="Shipment Details"
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                  >
                    <Descriptions.Item label="From">
                      {shippingDetails?.data?.data?.from?.name} <br />
                      {shippingDetails?.data?.data?.from?.address1} <br />
                      {shippingDetails?.data?.data?.from?.address2}{" "}
                      {shippingDetails?.data?.data?.from?.address2 && <br />}
                      {shippingDetails?.data?.data?.from?.city} <br />
                      {shippingDetails?.data?.data?.from?.zip} <br />
                      {shippingDetails?.data?.data?.from?.phone} <br />
                    </Descriptions.Item>
                    <Descriptions.Item label="To">
                      {" "}
                      {shippingDetails?.data?.data?.to?.name} <br />
                      {shippingDetails?.data?.data?.to?.address1} <br />
                      {shippingDetails?.data?.data?.to?.address2}{" "}
                      {shippingDetails?.data?.data?.to?.address2 && <br />}
                      {shippingDetails?.data?.data?.to?.city} <br />
                      {shippingDetails?.data?.data?.to?.zip} <br />
                      {shippingDetails?.data?.data?.to?.phone} <br />
                    </Descriptions.Item>

                    <Descriptions.Item label="COD">
                      {shippingDetails?.data?.data?.cod_amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Order No">
                      {shippingDetails?.data?.data?.order_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Description">
                      {" "}
                      {shippingDetails?.data?.data?.description}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <br />

                <Form onSubmit={handleSubmit(this.handleSubmit)}>
                  <FormItem
                    {...TailFormItemLayout}
                    label="Barcode Number"
                    required
                  >
                    <Field
                      formItemLayout={formItemLayout}
                      name="barcodeNumber"
                      component={AInputField}
                      placeholder="Enter Barcode Number"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    />
                  </FormItem>
                  {/* <Field
                      formItemLayout={formItemLayout}
                      name="carrier"
                      component={AInputField}
                      placeholder="Carrier"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    />
                    <Field
                      formItemLayout={formItemLayout}
                      name="carrierName"
                      component={AInputField}
                      placeholder="Carrier Name"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    /> */}
                  {/* <FormItem {...TailFormItemLayout} label="Tracking URL">
                    <Field
                      formItemLayout={formItemLayout}
                      name="trackingUrl"
                      component={AInputField}
                      addonBefore="https://"
                      placeholder="Enter Tracking URL"
                      onFocus={this.handleInputFocus}
                      hasFeedback
                    />
                  </FormItem> */}

                  <FormItem
                    {...TailFormItemLayout}
                    label="Pickup Vehicle Type"
                    required
                  >
                    <Field
                      showSearch
                      formItemLayout={formItemLayout}
                      name="vehicleType"
                      component={ASelectField}
                      hasFeedback
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={this.getCityHandler}
                      // defaultValue="Bike"
                    >
                      {pickupVehicleTypes.map((vehicleType, i) => {
                        return (
                          <Option key={i} value={vehicleType.value}>
                            {vehicleType.label}
                          </Option>
                        );
                      })}
                    </Field>
                  </FormItem>

                  <FormItem style={{ marginBottom: "0" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      disabled={pristine || submitting}
                      style={{
                        marginRight: "10px",
                        marginBottom: "0",
                        marginLeft: "10px",
                      }}
                    >
                      {isUpdate ? "Update" : "Confirm"}
                    </Button>
                    <Button
                      disabled={pristine || submitting}
                      onClick={reset}
                      style={{ marginBottom: "0" }}
                      className="float-right"
                    >
                      Clear Form
                    </Button>
                  </FormItem>
                </Form>
                {/* <div style={{ marginTop: "30px", textAlign: "end" }}>
                    <Button
                      type="primary"
                      className="mb-0 mr-0"
                      onClick={this.props.onCancel}
                      // disabled={pristine || submitting}
                      htmlType="submit"
                    >
                      {isUpdate ? "Update" : "Confirm"}
                    </Button>
                  </div> */}
              </TabPane>
            </Tabs>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.barcodeNumber) {
    errors.barcodeNumber = "Barcode Number is required";
  }
  // if (!values.trackingUrl) {
  //   errors.trackingUrl = "Tracking URL is required";
  // } else if (
  //   !/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/i.test(
  //     values.trackingUrl
  //   )
  // ) {
  //   errors.trackingUrl = "Invalid Tracking URL";
  // }

  return errors;
};

function mapStateToProps(state) {
  return {
    shippingAddressList: state.OrderManagement.shippingAddressList,
    shippingDetails: state.Cart.getShippingDetails,
    deliveryOrderShippingHandler: state.Cart.deliveryOrderShippingHandler,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    orderManagementActions: bindActionCreators(
      orderManagementActions,
      dispatch
    ),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "DomesticOrderTrackingModel",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(DomesticOrderTrackModelBody))
);
