import React, { Component } from "react";
import { Field } from "redux-form";
import { Modal, Button, Icon, Card, Input, InputNumber } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";
import { DEFAULT_CURRENCY } from "../../../../constants";

const AInputField = makeField(Input);
const ANumberField = makeField(InputNumber);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 6 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 18 },
    xl: { span: 18 },
  },
};

class ProductQuickVariationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      combinationsArray: props.productVariations,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { variations } = this.props;

    if (variations && variations !== prevProps.variations) {
      // var combinationsArray = getVariationTableInitValues(variations);

      this.setState({
        rows: this.props.variations,
        // combinationsArray: combinationsArray,
      });
    }
  }

  handleAddRow = () => {
    const item = {
      name: "",
      mobile: "",
    };
    this.setState({
      combinationsArray: [...this.state.combinationsArray, item],
    });
  };

  warningHandle = () => {
    const { isEnableProductVariable } = this.props;
    var modalTitle = isEnableProductVariable
      ? "Please Add the Variation First, "
      : "Please Enable Product Variable";
    Modal.info({
      title: modalTitle,
      content: (
        <div>
          <p></p>
        </div>
      ),
      onOk() {},
    });
  };

  render() {
    const {
      variations,
      productVariations,
      isEnableProductVariable,
    } = this.props;

    let isReadyToAddCombinations =
      Array.isArray(variations) &&
      variations.length !== 0 &&
      variations[0].values !== "0";

    if (isReadyToAddCombinations && isEnableProductVariable) {
      return (
        <Card style={{ backgroundColor: "#fafafa" }}>
          <div style={{ overflow: "auto" }}>
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <tr>
                  {variations &&
                    variations.map((variation, i) => {
                      return (
                        <th className="text-center" key={i}>
                          {variation}
                        </th>
                      );
                    })}
                  <th className="text-center"> Price({DEFAULT_CURRENCY}) </th>
                  <th className="text-center"> Availability </th>
                </tr>
              </thead>
              <tbody>
                {productVariations.map((item, idx) => (
                  <tr id={item.id} key={item.id}>
                    {variations.length > 0 && (
                      <td className="jr-fld-catch">
                        <Field
                        formItemLayout={formItemLayout}
                        name={`var:${idx}:${variations[0]}`}
                        type="text"
                        component={AInputField}
                        hasFeedback
                        // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                        disabled
                      />

                      </td>
                    )}
                    {variations.length > 1 && (
                      <td className="jr-fld-catch">
                        <Field
                        formItemLayout={formItemLayout}
                        name={`var:${idx}:${variations[1]}`}
                        type="text"
                        component={AInputField}
                        hasFeedback
                        // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                        disabled
                      />
                      </td>
                    )}
                    {variations.length > 2 && (
                      <td className="jr-fld-catch">
                        <Field
                        formItemLayout={formItemLayout}
                        name={`var:${idx}:${variations[2]["label"]}`}
                        type="text"
                        component={AInputField}
                        hasFeedback
                        disabled
                        // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                      />

                      </td>
                    )}
                    <td className="jr-fld-catch">
                      <Field
                        formItemLayout={formItemLayout}
                        name={`var:${idx}:price`}
                        // type="number"
                        component={ANumberField}
                        hasFeedback
                        onWheel={(e) => e.target.blur()}
                        min="0"
                      />
                    </td>
                    <td className="jr-fld-catch">
                      <Field
                        formItemLayout={formItemLayout}
                        name={`var:${idx}:qty`}
                        // type="number"
                        component={ANumberField}
                        hasFeedback
                        // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      );
    } else if (isEnableProductVariable) {
      return (
        <Card style={{ backgroundColor: "#fafafa" }}>
          <div className="" style={{ overflow: "auto" }}>
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <tr>
                  {variations &&
                    variations.map((variation, i) => {
                      return (
                        <th className="text-center" key={i}>
                          {variation}
                        </th>
                      );
                    })}
                  <th className="text-center"> Price({DEFAULT_CURRENCY}) </th>
                  <th className="text-center"> Availability </th>
                  <th />
                </tr>
              </thead>
            </table>
          </div>

          <Button
            block
            onClick={this.warningHandle}
            className="gx-btn-secondary gx-pulse-effect"
          >
            <Icon type="plus" style={{ verticalAlign: "middle" }} /> Add
            Combinations
          </Button>
        </Card>
      );
    } else {
      return null;
    }
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// ProductQuickVariationsTable = reduxForm({
//   // form: "product_quick_update", // a unique identifier for this form
//   enableReinitialize: true,
// })(ProductQuickVariationsTable);

// You have to connect() to any reducers that you wish to connect to yourself
// ProductQuickVariationsTable = connect(
//   (state) => ({
//     // initialValues: state.account.data // pull initial values from account reducer
//     initialValues: state.ProductManagement.variationTableDto,
//     // pull initial values from account reducer
//   })
//   // { load: loadAccount } // bind account loading action creator
// )(ProductQuickVariationsTable);

export default ProductQuickVariationsTable;
//399
