import React, { useEffect, useState } from "react";
import MapPicker from "react-google-map-picker";
import { Button, Modal } from "antd";
import { usePosition } from "use-position";
import { GOOGLE_MAP_API_KEY } from "../../../../../../constants";

const LocationSelectModal = ({ defaultValue, onChange }) => {
  const { latitude, longitude, error } = usePosition();
  const [location, setLocation] = useState({ lat: 6.927111, lng: 79.861211 });
  const [defaultLocation, setDefaultLocation] = useState({
    lat: 6.927111,
    lng: 79.861211,
  });
  const [zoom, setZoom] = useState(14);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (
      visible &&
      !defaultValue.lat &&
      !defaultValue.lng &&
      latitude &&
      longitude &&
      !error
    ) {
      setLocation({
        lat: round(latitude),
        lng: round(longitude),
      });
      onChange({
        lat: round(latitude),
        lng: round(longitude),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude, error, visible]);

  useEffect(() => {
    if (defaultValue.lat && defaultValue.lng) {
      setDefaultLocation(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // useEffect(() => {
  //     onChange(defaultLocation)
  // }, [defaultLocation]);

  useEffect(() => {
    if (defaultValue?.lat && defaultValue?.lng) {
      setLocation(defaultValue);
    }
  }, [defaultValue]);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: round(lat), lng: round(lng) });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function saveLocationHandler() {
    onChange(location);
    setVisible(false);
  }

  function closeModalHandler() {
    setLocation(defaultValue);
    setVisible(false);
  }

  function round(num) {
    return +(Math.round(num + "e+9") + "e-9");
  }

  return (
    <>
      {defaultValue?.lat && defaultValue?.lng && (
        <p
          style={{
            fontSize: "14px",
            verticalAlign: "middle",
            display: "inline-block",
            marginRight: "15px",
          }}
        >
          {defaultValue.lat}° N, {defaultValue.lng}° E{" "}
        </p>
      )}
      <Button onClick={() => setVisible(!visible)}>
        <i className="fa-solid fa-location-dot"></i>
        {defaultValue?.lat && defaultValue?.lng ? "Change" : "Set Location"}
      </Button>
      {visible && (
        <Modal
          width={"80%"}
          visible={visible}
          title="Please Pick the Location"
          onOk={() => saveLocationHandler()}
          onCancel={() => closeModalHandler()}
          footer={[
            <Button key="back" onClick={() => closeModalHandler()}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => saveLocationHandler()}
            >
              Ok
            </Button>,
          ]}
        >
          <div>
            <div className="mb-2">
              Location: {location.lat}° N, {location.lng}° E
            </div>
            <div>
              <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: "350px", maxHeight: "50%", width: "100%" }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                apiKey={GOOGLE_MAP_API_KEY}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LocationSelectModal;
