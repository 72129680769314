// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getProduct: createAction(types.GET_PRODUCT),
  getProductSuccess: createAction(types.GET_PRODUCT_SUCCESS),
  getProductFail: createAction(types.GET_PRODUCT_FAILED),

  getRelatedProductsForProduct: createAction(
    types.GET_RELATED_PRODUCTS_FOR_PRODUCT
  ),
  getRelatedProductsForProductSuccess: createAction(
    types.GET_RELATED_PRODUCTS_FOR_PRODUCT_SUCCESS
  ),
  getRelatedProductsForProductFail: createAction(
    types.GET_RELATED_PRODUCTS_FOR_PRODUCT_FAILED
  ),

  getHomeProducts: createAction(types.GET_HOME_PRODUCTS),
  getHomeProductsSuccess: createAction(types.GET_HOME_PRODUCTS_SUCCESS),
  getHomeProductsFail: createAction(types.GET_HOME_PRODUCTS_FAILED),

  selectItem: createAction(types.SELECT_ITEM),
  clearSelectItem: createAction(types.CLEAR_SELECT_ITEM),

  initializeReduxCart: createAction(types.INITIALIZE_REDUX_CART),
  initializeReduxCheckoutCart: createAction(
    types.INITIALIZE_REDUX_CHECKOUT_CART
  ),

  addCartItem: createAction(types.ADD_CART_ITEM),
  updateExistCartItem: createAction(types.UPDATE_EXIST_CART_ITEM),

  checkoutItems: createAction(types.CHECKOUT_ITEMS),

  cartSettingsHandler: createAction(types.CART_SETTINGS_HANDLER),

  getShippingCost: createAction(types.GET_SHIPPING_COST),
  getShippingCostSuccess: createAction(types.GET_SHIPPING_COST_SUCCESS),
  getShippingCostFail: createAction(types.GET_SHIPPING_COST_FAILED),

  makePayment: createAction(types.MAKE_PAYMENT),
  makePaymentSuccess: createAction(types.MAKE_PAYMENT_SUCCESS),
  makePaymentFail: createAction(types.MAKE_PAYMENT_FAILED),

  makeAuthorizePayment: createAction(types.MAKE_AUTHORIZE_PAYMENT),
  makeAuthorizePaymentSuccess: createAction(
    types.MAKE_AUTHORIZE_PAYMENT_SUCCESS
  ),
  makeAuthorizePaymentFail: createAction(types.MAKE_AUTHORIZE_PAYMENT_FAILED),

  secureAcceptanceHandler: createAction(types.SECURE_ACCEPTANCE_HANDLER),

  selectShippingMethod: createAction(types.SELECT_SHIPPING_METHOD),

  productUserSelectionsHandler: createAction(
    types.PRODUCT_USER_SELECTIONS_HANDLER
  ),

  productsSelectInCart: createAction(types.PRODUCTS_SELECT_IN_CART),
  productsSelectCartSuccess: createAction(
    types.PRODUCTS_SELECT_IN_CART_SUCCESS
  ),
  productsSelectChangeInCartFail: createAction(
    types.PRODUCTS_SELECT_IN_CART_FAILED
  ),

  orderSummery: createAction(types.ORDER_SUMMERY),
  orderSummerySuccess: createAction(types.ORDER_SUMMERY_SUCCESS),
  orderSummeryFail: createAction(types.ORDER_SUMMERY_FAILED),

  productQuantityChangeInCart: createAction(
    types.PRODUCT_QUANTITY_CHANGE_IN_CART
  ),
  productQuantityChangeInCartSuccess: createAction(
    types.PRODUCT_QUANTITY_CHANGE_IN_CART_SUCCESS
  ),
  productQuantityChangeInCartFail: createAction(
    types.PRODUCT_QUANTITY_CHANGE_IN_CART_FAILED
  ),

  removeCartItem: createAction(types.REMOVE_CART_ITEM),

  removeProductFromCart: createAction(types.REMOVE_PRODUCT_FROM_CART),
  removeProductFromCartSuccess: createAction(
    types.REMOVE_PRODUCT_FROM_CART_SUCCESS
  ),
  removeProductFromCartCartFail: createAction(
    types.REMOVE_PRODUCT_FROM_CART_FAILED
  ),

  addUserSelections: createAction(types.ADD_USER_SELECTIONS),
  clearUserSelections: createAction(types.CLEAR_USER_SELECTIONS),

  confirmShippingInfo: createAction(types.CONFIRM_SHIPPING_INFO),
  confirmCardInfo: createAction(types.CONFIRM_CARD_INFO),

  updateShippingCost: createAction(types.UPDATE_SHIPPING_COST),
  updateShippingCostSuccess: createAction(types.UPDATE_SHIPPING_COST_SUCCESS),
  updateShippingCostFail: createAction(types.UPDATE_SHIPPING_COST_FAILED),

  selectVariations: createAction(types.SELECT_VARIATIONS),
  deselectVariations: createAction(types.DESELECT_VARIATIONS),

  addWishlistItem: createAction(types.ADD_WISHLIST_ITEM),
  addWishlistItemSucess: createAction(types.ADD_WISHLIST_ITEM_SUCCESS),
  addWishlistItemFail: createAction(types.ADD_WISHLIST_ITEM_FAILED),

  removeWishlistItem: createAction(types.REMOVE_WISHLIST_ITEM),
  removeWishlistItemSucess: createAction(types.REMOVE_WISHLIST_ITEM_SUCCESS),
  removeWishlistItemFail: createAction(types.REMOVE_WISHLIST_ITEM_FAILED),

  getRelatedProducts: createAction(types.GET_RELATED_PRODUCTS),
  getRelatedProductsSucess: createAction(types.GET_RELATED_PRODUCTS_SUCCESS),
  getRelatedProductsFail: createAction(types.GET_RELATED_PRODUCTS_FAILED),

  addProductFAQS: createAction(types.ADD_PRODUCT_FAQS),
  addProductFAQSSucess: createAction(types.ADD_PRODUCT_FAQS_SUCCESS),
  addProductFAQSFail: createAction(types.ADD_PRODUCT_FAQS_FAIL),

  //RESET
  resetCheckoutItems: createAction(types.RESET_CHECKOUT_ITEMS),

  //Toggle Sidebar
  sidebarToggle: createAction(types.SIDEBAR_TOGGLE),
  sellerSidebarToggle: createAction(types.SELLER_SIDEBAR_TOGGLE),

  //Settings
  settingsUpdate: createAction(types.SETTINGS_UPDATE),

  //Payment type handle
  paymentMethodHandle: createAction(types.PAYMENT_METHOD_HANDLE),

  //clear product info
  clearProductInfo: createAction(types.CLEAR_PRODUCT_INFO),

  // check product quantity
  checkOrderQuantity: createAction(types.CHECK_ORDER_QUANTITY),
  checkOrderQuantitySucess: createAction(types.CHECK_ORDER_QUANTITY_SUCCESS),
  checkOrderQuantityFail: createAction(types.CHECK_ORDER_QUANTITY_FAILED),

  // domestic product update
  domesticProductUpdate: createAction(types.DOMESTIC_SHIPPINH_PRODUCT_UPDATE),
  domesticProductUpdateSucess: createAction(
    types.DOMESTIC_SHIPPINH_PRODUCT_UPDATE_SUCCESS
  ),
  domesticProductUpdateFail: createAction(
    types.DOMESTIC_SHIPPINH_PRODUCT_UPDATE_FAILED
  ),

  // selected domestic shipping location
  selectedDomesticShippingLocation: createAction(
    types.SELECTED_DOMESTIC_SHIPPING_LOCATION
  ),

  // get all DISTRICTS
  getAllDistricts: createAction(types.GET_ALL_DISTRICTS),
  getAllDistrictsSucess: createAction(types.GET_ALL_DISTRICTS_SUCCESS),
  getAllDistrictsFail: createAction(types.GET_ALL_DISTRICTS_FAILED),

  // get area by DISTRICT
  getAreaByDistrict: createAction(types.GET_AREA_BY_DISTRICT),
  getAreaByDistrictSucess: createAction(types.GET_AREA_BY_DISTRICT_SUCCESS),
  getAreaByDistrictFail: createAction(types.GET_AREA_BY_DISTRICT_FAILED),

  // Initialize Location Picker Value
  initializeLocationPickerValue: createAction(
    types.INITIALIZE_LOCATION_PICKER_VALUE
  ),

  //clear checkout Details
  clearCheckoutDetails: createAction(types.CLEAR_CHECKOUT_DETAILS),

  // get shipping details
  getShippingDetails: createAction(types.GET_SHIPPING_DETAILS),
  getShippingDetailsSucess: createAction(types.GET_SHIPPING_DETAILS_SUCCESS),
  getShippingDetailsFail: createAction(types.GET_SHIPPING_DETAILS_FAILED),

  // delivery order shipping handler
  deliveryOrderShippingHandler: createAction(
    types.DELIVERY_ORDER_SHIPPING_HANDLER
  ),
  deliveryOrderShippingHandlerSucess: createAction(
    types.DELIVERY_ORDER_SHIPPING_HANDLER_SUCCESS
  ),
  deliveryOrderShippingHandlerFail: createAction(
    types.DELIVERY_ORDER_SHIPPING_HANDLER_FAILED
  ),

  getBankPaymentDetails: createAction(types.GET_BANK_PAYMENT_DETAILS),
  getBankPaymentDetailsSucess: createAction(
    types.GET_BANK_PAYMENT_DETAILS_SUCCESS
  ),
  getBankPaymentDetailsFail: createAction(
    types.GET_BANK_PAYMENT_DETAILS_FAILED
  ),
};
