import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import "./OnShopFooter.scss";
import {
  IS_ENABLED_SUPPORT_CENTER_MODULE,
  IS_ENABLED_FAQ_MODULE,
} from "../../../constants";

const OnShopFooter = () => {
  return (
    <footer className="new_footer_area bg_color">
      <div className="new_footer_top nw-bckgnd">
        <div className="gx-container">
          <Row>
            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <div>
                {/* <div className="col-sm-6 col-lg-3"> */}
                <div className="widget widget-about">
                  <img
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/logo.png`)}
                    // className="footer-logo"
                    alt="Footer Logo"
                    // width="200"
                    // height="50"
                  />
                  <p> The biggest collection of online stores. </p>
                  <p>
                    This is for all self employed in sri lanka. join with us.{" "}
                  </p>

                  <div className="widget-call">
                    <i className="icon-phone"></i>
                    Got Question? <br/> Call us: <a href="tel:0713108888">071 310 8888</a> / <a href="tel:0773108888">077 310 8888</a>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <div>
                <h3 className="f-title f_600 t_color f_size_18">Buy & Sell</h3>
                <ul
                  className="list-unstyled mmgnnftr"
                  style={{ marginBottom: "25px" }}
                >
                  <li>
                    <Link className="ftr-ancr" to="/register">
                      Register for Buying
                    </Link>
                  </li>
                  <li>
                    <Link className="ftr-ancr" to="/help/buyer">
                      Buying Information
                    </Link>
                  </li>
                  <li>
                    <Link className="ftr-ancr" to="/account/seller/register">
                      Register for Selling
                    </Link>
                  </li>
                  <li>
                    <Link className="ftr-ancr" to="/help/seller">
                      Selling Information
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="ftr-ancr"
                      to="/customer-service/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="ftr-ancr" to="/customer-service/terms-and-conditions">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>

                {/* <h3 className="f-title f_600 t_color f_size_18">App</h3>
                <ul
                  className="list-unstyled mmgnnftr"
                  style={{ marginBottom: "25px" }}
                >
                  <a
                    href="https://apps.apple.com/app/tradesbarn-online-shopping/id1538334605"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="footer"
                      style={{ width: 100 }}
                      src={require("../../../assets/images/appleapp.png")}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tradesbarn.app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="footer"
                      style={{ width: 100 }}
                      src={require("../../../assets/images/googleplay.png")}
                    />
                  </a>
                </ul> */}
              </div>
            </Col>

            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <div>
                <h3 className="f-title f_600 t_color f_size_18">
                  Help &amp; Contact
                </h3>
                <ul
                  className="list-unstyled mmgnnftr"
                  style={{ marginBottom: "25px" }}
                >
                  {IS_ENABLED_SUPPORT_CENTER_MODULE && (
                    <li>
                      <Link
                        className="ftr-ancr"
                        to="/support-center/buyer-registration"
                      >
                        Support Center
                      </Link>
                    </li>
                  )}
                  {IS_ENABLED_FAQ_MODULE && (
                    <li>
                      <Link className="ftr-ancr" to="/faq-section">
                        FAQ
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link className="ftr-ancr" to="/resolution-center/all">
                      Resolution Center
                    </Link>
                  </li>
                  <li>
                    <Link className="ftr-ancr" to="/help-and-contact">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="f-title f_600 t_color f_size_18">
                  Social Media
                </h3>

                <div className="social-container">
                  <ul className="social-icons">
                    <li>
                      <a
                        href={process.env.REACT_APP_SOCIAL_MEDIA_FB}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={process.env.REACT_APP_SOCIAL_MEDIA_TWITTER}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={process.env.REACT_APP_SOCIAL_MEDIA_LINKEDIN}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={process.env.REACT_APP_SOCIAL_MEDIA_INSTAGRAM}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="https://www.youtube.com/channel/UChipX2OXQhgLgto32w9T4WA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li> */}
                    <li>
                      <a
                        href={process.env.REACT_APP_SOCIAL_MEDIA_PINTEREST}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col xl={6} lg={6} md={6} sm={24} xs={24}>
              <div>
                <h3 className="f-title f_600 t_color f_size_18">Reach out</h3>

                <ul
                  className="list-unstyled mmgnnftr"
                  style={{ marginBottom: "25px" }}
                >
                  <li>
                    <a href="mailto:customercare@onshop.lk?subject = Feedback&body = Message">
                      customercare@onshop.lk
                    </a>
                  </li>

                  <li>No 788, Mahinda avenue, Homagama.</li>
                  <li><a href="tel:0713108888" style={{color: 'unset'}}>071 310 8888</a> / <a href="tel:0773108888" style={{color: 'unset'}}>077 310 8888</a></li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="cprgt-bar">
                <p>{process.env.REACT_APP_FOOTER_COPYRIGHT_TEXT}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
};

export default OnShopFooter;
