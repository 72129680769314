import { db } from "../firebase/firebase";
import * as exactMath from "exact-math";
import { countries, byIso } from "country-code-lookup";
import { GetPublicURL } from "./../services/storage.service";
import { GetCurrentUserID } from "./../services/auth.service";

let WishListListner = null;

const CONST_WARRANTY_TYPES = {
  no_warranty: "No Warranty",
  international_manufacturer_warranty: "International Manufacturer Warranty",
  local_seller_warranty: "Local Seller Warranty",
  international_seller_warranty: "International Seller Warranty",
  brand_warranty: "Brand Warranty",
  agent_warranty: "Agent Warranty",
};

const RETURN_METHODS = {
  no_returns: "No Returns",
  paid_by_buyer: "Paid by Buyer",
  paid_by_seller: "Paid by Seller",
};

export const GetWishListedStatusByProductIDs = async (product_ids) => {
  const user_id = await GetCurrentUserID();

  const res = product_ids.reduce((res, product_id) => {
    return { ...res, [product_id]: false };
  }, {});
  if (!user_id) return res;

  let wishlisted_product_list = [];
  for (let i = 0; i < product_ids.length; i += 10) {
    const wishlisted_product_list_query = await db
      .collection("wishlists")
      .where("user_id", "==", user_id)
      .where(
        "product_id",
        "in",
        Array.from(new Set(product_ids.slice(i, i + 10)))
      )
      .get();

    wishlisted_product_list = [
      ...wishlisted_product_list,
      ...wishlisted_product_list_query.docs.map((doc) => doc.data().product_id),
    ];
  }
  const is_wislisted = product_ids.reduce((res, product_id) => {
    return {
      ...res,
      [product_id]: wishlisted_product_list.includes(product_id),
    };
  }, {});
  return is_wislisted;
};

export const GetWishListedStatusByProductID = async (product_id) => {
  const user_id = await GetCurrentUserID();
  if (!user_id) return false;

  const wishlisted_product_list_query = await db
    .collection("wishlists")
    .where("user_id", "==", user_id)
    .where("product_id", "==", product_id)
    .get();
  return !!wishlisted_product_list_query.size;
};

export const GetNotificationsCount = async (callback) => {
  try {
    const user_id = await GetCurrentUserID();
    if (!user_id)
      return callback({
        wishlist_count: 0,
        notification_count: 0,
      });
    const NotificationsListner = db
      .collection("users")
      .where("user_id", "==", user_id)
      .onSnapshot((querySnapshot) => {
        const notifications = [];
        querySnapshot.forEach((doc) => {
          const counts = doc.data();
          notifications.push({
            wishlist_count: counts.wishlist_count || 0,
            notification_count: counts.notification_count || 0,
          });
        });
        callback(notifications[0], NotificationsListner);
      });
  } catch (err) {
    console.error(err);
  }
};

export const GetDiscountedProducts = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("ProductsByDiscount")
        .get()
        .then((discounted_products) => {
          if (!discounted_products || !discounted_products.data())
            return resolve([]);
          const data = discounted_products.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

export const GetMetrics = () => {
  const defaultMetrics = {
    DEFAULT_WEIGHT_METRIC: "g",
    DEFAULT_LENGTH_METRIC: "cm",
  };

  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("Metrics")
        .get()
        .then((metrics) => {
          if (!metrics || !metrics.data()) return resolve(defaultMetrics);
          const data = metrics.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve(defaultMetrics);
    }
  });
};

export const GetTopSellers = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("TopSellers")
        .get()
        .then((top_sellers) => {
          if (!top_sellers || !top_sellers.data()) return resolve([]);
          const data = top_sellers.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

export const GetFeaturedProducts = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("FeaturedProducts")
        .get()
        .then((featured_products) => {
          if (!featured_products || !featured_products.data())
            return resolve([]);
          const data = featured_products.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

export const GetLatestProducts = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("LatestProductCarousel")
        .get()
        .then((latest_products) => {
          if (!latest_products || !latest_products.data()) return resolve([]);
          const data = latest_products.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

export const GetPopularProducts = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("PopularProductCarousel")
        .get()
        .then((popular_products) => {
          if (!popular_products || !popular_products.data()) return resolve([]);
          const data = popular_products.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

export const GetAllCategories = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("AllCategories")
        .get()
        .then((all_categories) => {
          if (!all_categories || !all_categories.data()) return resolve([]);
          const data = all_categories.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

export const GetPopularCategories = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("PopularCategories")
        .get()
        .then((popular_categories) => {
          if (!popular_categories || !popular_categories.data())
            return resolve([]);
          const data = popular_categories.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

export const GetLandingPageBrands = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("brands")
        .get()
        .then((brands) => {
          if (!brands || !brands.data()) return resolve([]);
          const data = brands.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

const NumberWithCommas = (number) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const GetProductByID = (product_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const product_promise = db.collection("products").doc(product_id).get();
      // const metrics_promise = GetMetrics();
      const is_wishlisted_promise = GetWishListedStatusByProductID(product_id);

      const product_snapshot = await product_promise;
      if (!product_snapshot.exists)
        throw Error(`Invalid Product ID ${product_id}`);
      const product = product_snapshot.data();

      const product_variations_promise = db
        .collection("products")
        .doc(product_id)
        .collection("variations")
        .get();
      const seller_promise = db.collection("users").doc(product.user_id).get();
      const category_promise = db
        .collection("categories")
        .doc(product.category_id)
        .get();

      const seller_snapshot = await seller_promise;
      if (!seller_snapshot.exists)
        throw Error(`Invalid Seller ID ${product.user_id}`);
      const seller = seller_snapshot.data();

      // const metrics = await metrics_promise;
      const category_snapshot = await category_promise;
      if (!category_snapshot.exists)
        throw Error(`Invalid Category ID ${product.category_id}`);
      const category = category_snapshot.data();

      let total_qty = product.qty || 0;
      const product_variations_snapshot = await product_variations_promise;
      const product_variations = product_variations_snapshot.docs.map((doc) => {
        const data = doc.data();
        total_qty += +data.qty;
        return {
          ...data,
          price: NumberWithCommas(exactMath.div(
              exactMath.mul(data.price, 100 - product.discount_percentage),
              100
            ).toFixed(2)),
          old_price: NumberWithCommas(data.price),
          id: doc.id,
        };
      });
      if (
        product.default_image_index &&
        product.images &&
        product.images.length
      )
        [product.images[0], product.images[product.default_image_index]] = [
          product.images[product.default_image_index],
          product.images[0],
        ];
      const images =
        product.images && product.images.length
          ? product.images.map((img) => GetPublicURL(img.default))
          : [GetPublicURL("default-product.jpg")];
      const variation_img_mapping = {};
      Object.keys(product.variation_images || {}).forEach((variation) => {
        // need to verify ************
        product.variation_images[variation] = GetPublicURL(
          product.variation_images[variation]
        );
        const img = product.variation_images[variation];
        variation_img_mapping[variation] = images.length;
        images.push(img);
      });

      const product_address = product.address || {};
      const res = {
        data: {
          data: {
            id: product_id,
            description: product.description,
            highlight: product.highlight,
            created_at: product.created_at,
            seller: {
              id: product.seller_id || product.user_id,
              name: seller.name,
              image: GetPublicURL(seller.image || "default-seller.png"),
              ratings_value: seller.rating || 0.0,
              ratings_count: seller.ratings_count || 0,
              joined_at: seller.created_at,
            },
            height: product.height,
            width: product.width,
            images,
            country: product_address.country
              ? byIso(product_address.country).country
              : null,
            available: product.qty || 0,
            category: {
              id: product.category_id,
              name: category.name,
            },
            zip: product_address.zip,
            name: product.name,
            ratings_count: product.ratings_count,
            ratings_value: exactMath
              .div(product.ratings_value, product.ratings_count || 1)
              .toFixed(1),
            ratings: {
              ratings_value: exactMath
                .div(product.ratings_value, product.ratings_count || 1)
                .toFixed(1),
              ratings_percentage: [
                product.rating1 || 0,
                product.rating2 || 0,
                product.rating3 || 0,
                product.rating4 || 0,
                product.rating5 || 0,
              ].map((rating) => ((rating / product.ratings_count) * 100) | 0),
              ratings_count: [
                product.rating1 || 0,
                product.rating2 || 0,
                product.rating3 || 0,
                product.rating4 || 0,
                product.rating5 || 0,
              ],
            },
            price: product.variations_available
              ? `${NumberWithCommas(product.price.split('-')[0])}-${NumberWithCommas(product.price.split('-')[1])}`
              : NumberWithCommas(exactMath.div(
                    exactMath.mul(
                      product.price,
                      100 - product.discount_percentage
                    ),
                    100
                  ).toFixed(2)),
            old_price:
              product.discount_percentage && !product.variations_available
                ? NumberWithCommas(parseFloat(product.price || "0").toFixed(2))
                : undefined,
            qty: product.qty || 0,
            total_qty: total_qty,
            sold: product.sold || 0,
            length: product.length,
            brand: product.brand || "None",
            discount_percentage: product.discount_percentage || 0,
            city: product_address.city,
            variation_types: product.variation_types,
            variation_values: product.variation_values,
            variations: (product_variations || []).map((product_variation) => {
              return {
                ...product_variation,
                image:
                  product.image_enabled_variation &&
                  product.image_enabled_variation !== "default"
                    ? variation_img_mapping[
                        product_variation[product.image_enabled_variation]
                      ] || 0
                    : 0,
              };
            }),
            variation_images: variation_img_mapping,
            image_enabled_variation: product.image_enabled_variation,
            weight: product.weight,
            specifications: {
              ...(product.item_specs || {}),
              Height: `${product.height} ${product.length_measurement}`,
              Width: `${product.width} ${product.length_measurement}`,
              Weight: `${product.weight} ${product.weight_measurement}`,
              Length: `${product.length} ${product.length_measurement}`,
              Brand: product.brand || "None",
            },
            package_details: {
              package_type: product.package_type || "",
              height: `${product.height} ${product.length_measurement}`,
              width: `${product.width} ${product.length_measurement}`,
              weight: `${product.weight} ${product.weight_measurement}`,
              length: `${product.length} ${product.length_measurement}`,
            },
            condition: product.condition || null,
            is_dangerous_good: product.is_dangerous_good || false,
            dangerous_good_type: product.dangerous_good_type || [],
            warranty: CONST_WARRANTY_TYPES[product.warranty],
            excluded_shipping_countries: countries
              .map((country) => country.iso3)
              .filter((country) =>
                (product.excluded_shipping_countries || []).includes(country)
              )
              .map((country) => byIso(country).country),
            shipping_handling_days: `${product.shipping_handling_days} Days`,
            return_accepted_method:
              RETURN_METHODS[product.return_accepted_method],
            is_reviews_exists: product.is_reviews_exists || false,
            is_faqs_exists: product.is_faqs_exists || false,
            is_wishlisted: await is_wishlisted_promise,
            international_shipping_estimated_delivery_days_min:
              product.international_shipping_estimated_delivery_days_min ||
              null,
            international_shipping_estimated_delivery_days_max:
              product.international_shipping_estimated_delivery_days_max ||
              null,
            international_shipping_estimated_delivery_note:
              product.international_shipping_estimated_delivery_note || null,
            domestic_shipping_estimated_delivery_days_min:
              product.domestic_shipping_estimated_delivery_days_min || null,
            domestic_shipping_estimated_delivery_days_max:
              product.domestic_shipping_estimated_delivery_days_max || null,
            domestic_shipping_estimated_delivery_note:
              product.domestic_shipping_estimated_delivery_note || null,
          },
        },
      };
      resolve(res);
    } catch (err) {
      reject(`Invalid Product`);
    }
  });
};

/**
 * Get Last Wishlisted Products
 * @param {Callback} callback
 */
export const GetWishlistedProducts = async (callback, no_of_products = 10) => {
  try {
    const user_id = await GetCurrentUserID();
    if (!user_id) {
      callback([]);
      return null;
    }

    const listner = db
      .collection("wishlists")
      .where("user_id", "==", user_id)
      .orderBy("created_at", "desc")
      .limit(no_of_products)
      .onSnapshot((querySnapshot) => {
        callback(querySnapshot.docs.map((prod) => prod.data()));
      });
    WishListListner = listner;
    return WishListListner;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get All Delivery Areas
 * @param {Callback} callback
 */
export const GetAllDeliveryAreas = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("delivery_areas_all")
        .get()
        .then((delivery_areas) => {
          if (!delivery_areas || !delivery_areas.data()) return resolve([]);
          const data = delivery_areas.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

/**
 * Get All delivery area districts
 * @param {Callback} callback
 */
export const GetAllDiscticts = () => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc("delivery_areas_districts")
        .get()
        .then((delivery_areas) => {
          if (!delivery_areas || !delivery_areas.data()) return resolve([]);
          const data = delivery_areas.data();
          const districtList = JSON.parse(data.data) || [];
          districtList.sort();
          return resolve(districtList);
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};

/**
 * Get Delivery Areas By district
 * @param {Callback} callback
 */
export const GetDeliveryAreasByDistrict = (district) => {
  return new Promise((resolve, reject) => {
    try {
      db.collection("cache")
        .doc(`delivery_areas_${district}`)
        .get()
        .then((delivery_areas) => {
          if (!delivery_areas || !delivery_areas.data()) return resolve([]);
          const data = delivery_areas.data();
          return resolve(JSON.parse(data.data));
        });
    } catch (err) {
      console.error(err);
      resolve([]);
    }
  });
};
