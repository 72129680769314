import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Button, Spin, Modal } from "antd";
import { productManagementActions } from "../../../../modules/SellerDashboard/ProductManagement/ducks";
import { DEFAULT_CURRENCY } from "../../../../constants";
import ProductQuickEditForm from "./ProductQuickEditForm";
import { reduxForm} from "redux-form";
const { confirm } = Modal;

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      hits: [],
      filteredInfo: null,
      sortedInfo: null,
      expandedRows: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { hits, createProduct, productManagementActions } = this.props;
    if (JSON.stringify(hits) !== JSON.stringify(prevProps.hits)) {
      
      this.setState({ hits: hits });
    }
    if (
      createProduct.data &&
      JSON.stringify(createProduct.data) !==
        JSON.stringify(prevProps.createProduct.data) &&
      createProduct.data.success &&
      createProduct.data.data
    ) {
      this.setState(
        { hits: [createProduct.data.data, ...this.state.hits] },
        function () {
          productManagementActions.clearCreateProduct();
        }
      );
    }
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleDeleteProduct = (data) => {
    const { productManagementActions } = this.props;
    confirm({
      title: "Are you sure delete this product?",
      content: data.name,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        productManagementActions.deleteProduct({
          id: data.objectID,
          callback: this.updatedDeleteProductHandler,
        });
      },
      onCancel: () => {
        
      },
    });
  };

  updatedDeleteProductHandler = (id) => {
    const { hits } = this.state;
    let prevHits = Object.assign([], hits);
    let index = prevHits.findIndex((product) => product.objectID === id);
    prevHits.splice(index, 1);
    this.setState({ hits: prevHits });
  };

  handleChangeProductActiveStatus = (id) => {
    const { productManagementActions } = this.props;
    productManagementActions.changeProductActiveStatus({
      id: id,
      callback: this.updateProductStatus,
    });
  };

  updateProductStatus = (id) => {
    const { hits } = this.state;
    let prevHits = Object.assign([], hits);
    prevHits.forEach((product, index) => {
      if (product.objectID === id) {
        let prevProductStatus = product.active;
        product.active = !prevProductStatus;
      }
    });
    this.setState({ hits: prevHits });
  };

  navigateProduct = (id) => {
    const win = window.open(`/product/${id}`, "_blank");
    win.focus();
  };

  navigateToEditProduct = (id) => {
    const win = window.open(`/seller/dashboard/product/update/${id}`, "_blank");
    win.focus();
  };

  handleRowExpand = (record) => {
    // if a row is expanded, collapses it, otherwise expands it
    this.setState({
      expandedRows:[record.id]
    })


    const {
      available,
      discount_percentage,
      price,
      variations,
      variations_available,
    } = record;

    //variation array here
    // debugger;
    var productVariations = variations;
    var variationsTable = {};
    if (Array.isArray(productVariations)) {
      // debugger;
      variationsTable = productVariations.reduce((res, variation, idx) => {
        Object.keys(variation).forEach(
          (key) => (res[`var:${idx}:${key}`] = variation[key])
        );
        return res;
      }, {});
    }

    this.setState({
      productVariations: productVariations,
      isEnableProductVariable: variations_available,
    });

    let initObject = {
      price: price,
      productStock: available,
      discount: discount_percentage,
      ...variationsTable,
    };
    this.props.initialize(initObject);
  }

  render() {
    const { changeProductActiveStatus, deleteProduct, quickUpdateProduct } =
      this.props;

    const columns = [
      {
        title: "Product",
        width: "110px",
        render: (text, record) => {
          return (
            <img
              alt="avatar"
              src={record.images && record.images[0]}
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            />
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Price",
        width: "150px",
        render: (text, record) => (
          <span className="texi-clr-org">{`${DEFAULT_CURRENCY} ${record.price}`}</span>
        ),
      },
      {
        title: "Availability",
        width: "80px",
        dataIndex: "total_qty",
        key: "address",
      },
      {
        title: "Actions",
        width: "290px",
        render: (text, record) => {
          return (
            <>
              {record.active ? (
                <Button
                  style={{ width: "120px" }}
                  className="gx-btn-outline-primary mb-2 mr-2"
                  onClick={() => this.navigateProduct(record.id)}
                >
                  <i className="fad fa-eye mr-1"></i>
                  View
                </Button>
              ) : (
                <Button
                  style={{ width: "120px" }}
                  className="gx-btn-outline-primary mb-2 mr-2"
                  onClick={() =>
                    this.props.history.push(`/product/${record && record.id}`)
                  }
                  disabled
                >
                  <i className="fad fa-eye mr-1"></i>
                  View
                </Button>
              )}

              <Button
                style={{ width: "120px" }}
                className="gx-btn-secondary mb-2 mr-2"
                onClick={() => this.navigateToEditProduct(record.id)}
              >
                <i className="fad fa-sync-alt mr-1"></i>
                Update
              </Button>

              <Button
                style={{ width: "120px" }}
                className={
                  record.active
                    ? "gx-btn-outline-sepia mb-0 mr-2"
                    : "gx-btn-outline-success mb-0 mr-2"
                }
                onClick={() =>
                  this.handleChangeProductActiveStatus(record.objectID)
                }
              >
                <i
                  className={
                    record.active
                      ? "fad fa-lightbulb mr-1"
                      : "fad fa-lightbulb-on mr-1"
                  }
                ></i>
                {record.active ? "Inactive" : "Active"}
              </Button>

              <Button
                style={{ width: "120px" }}
                className="gx-btn-outline-danger mb-0 mr-2"
                onClick={() => this.handleDeleteProduct(record)}
              >
                <i className="fad fa-trash-alt mr-1"></i>
                Delete
              </Button>
            </>
          );
        },
      },
    ];

    return (
      <Spin
        spinning={
          changeProductActiveStatus.pending ||
          deleteProduct.pending ||
          quickUpdateProduct.pending
        }
        className=" mt-3"
      >
        <Table
          className=""
          style={{ minWidth: "500px" }}
          columns={columns}
          // dataSource={this.props.hits.length === 0 ? [] : hits}
          dataSource={this.state.hits}
          onChange={this.handleChange}
          pagination={false}
          expandedRowKeys={this.state.expandedRows}
          onExpand={(expanded, record) => this.handleRowExpand(record)}
          rowKey="id" 
          expandedRowRender={(record) => {
            // this.props.initialize({ productPrice: 20 });
            return (
              <React.Fragment>
                <ProductQuickEditForm data={record} />
              </React.Fragment>
            );
          }}
          bordered
          TableRow
        />
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    createProduct: state.ProductManagement.createProduct,
    changeProductActiveStatus:
      state.ProductManagement.changeProductActiveStatus,
    deleteProduct: state.ProductManagement.deleteProduct,
    quickUpdateProduct: state.ProductManagement.quickUpdateProduct,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
  };
}

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(ProductList)
// );

export default withRouter(
  reduxForm({
    form: "product_quick_update",
    // validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ProductList))
);

