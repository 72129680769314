import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Modal, Select, Button, Icon, Card, Input } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";
import { DEFAULT_CURRENCY } from "../../../../constants";

const Option = Select.Option;
const ASelectField = makeField(Select);
const AInputField = makeField(Input);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 6 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 18 },
    xl: { span: 18 },
  },
};

class ProductVariationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      combinationsArray: props.productVariations,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { variations } = this.props;

    if (variations && variations !== prevProps.variations) {
      // var combinationsArray = getVariationTableInitValues(variations);

      this.setState({
        rows: this.props.variations,
        // combinationsArray: combinationsArray,
      });
    }
  }

  handleAddRow = () => {
    const item = {
      name: "",
      mobile: "",
    };
    this.setState({
      combinationsArray: [...this.state.combinationsArray, item],
    });
  };

  warningHandle = () => {
    const { isEnableProductVariable } = this.props;
    var modalTitle = isEnableProductVariable
      ? "Please Add the Variation First, "
      : "Please Enable Product Variable";
    Modal.info({
      title: modalTitle,
      content: (
        <div>
          <p></p>
        </div>
      ),
      onOk() {},
    });
  };

  render() {
    const {
      variations,
      handleAddRow,
      handleRemoveSpecificRow,
      productVariations,
      isEnableProductVariable,
    } = this.props;

    console.log(this.props, "variation table")

    let isReadyToAddCombinations =
      Array.isArray(variations) &&
      variations.length !== 0 &&
      variations[0].values !== "0";

    if (isReadyToAddCombinations && isEnableProductVariable) {
      return (
        <Card style={{ backgroundColor: "#fafafa" }}>
          <div style={{ overflow: "auto" }}>
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <tr>
                  {variations &&
                    variations.map((variation, i) => {
                      return (
                        <th className="text-center" key={i}>
                          {variation.label}
                        </th>
                      );
                    })}
                  <th className="text-center"> Price({DEFAULT_CURRENCY}) </th>
                  <th className="text-center"> Availability </th>

                  <th />
                </tr>
              </thead>
              <tbody>
                {productVariations.map((item, idx) => (
                  <tr id={item.id} key={item.id}>
                    {variations.length > 0 && (
                      <td className="jr-fld-catch">
                        <Field
                          formItemLayout={formItemLayout}
                          name={`var:${idx}:${variations[0]["label"]}`}
                          component={ASelectField}
                          placeholder="Select"
                          hasFeedback
                        >
                          {variations &&
                            variations[0] &&
                            variations[0].values &&
                            variations[0].values.map((value, i) => {
                              return (
                                <Option value={value} key={value}>
                                  {value}
                                </Option>
                              );
                            })}
                        </Field>
                      </td>
                    )}
                    {variations.length > 1 && (
                      <td className="jr-fld-catch">
                        <Field
                          formItemLayout={formItemLayout}
                          name={`var:${idx}:${variations[1]["label"]}`}
                          component={ASelectField}
                          placeholder="Select"
                          hasFeedback
                        >
                          {variations &&
                            variations[1] &&
                            variations[1].values &&
                            variations[1].values.map((value, i) => {
                              return <Option value={value}>{value}</Option>;
                            })}
                        </Field>
                      </td>
                    )}
                    {variations.length > 2 && (
                      <td className="jr-fld-catch">
                        <Field
                          formItemLayout={formItemLayout}
                          component={ASelectField}
                          placeholder="Select"
                          hasFeedback
                        >
                          {variations &&
                            variations[2] &&
                            variations[2].values &&
                            variations[2].values.map((value, i) => {
                              return <Option value={value}>{value}</Option>;
                            })}
                        </Field>
                      </td>
                    )}
                    <td className="jr-fld-catch">
                      <Field
                        formItemLayout={formItemLayout}
                        name={`var:${idx}:price`}
                        type="number"
                        component={AInputField}
                        hasFeedback
                        onWheel={(e) => e.target.blur()}
                        min="0"
                      />
                    </td>
                    <td className="jr-fld-catch">
                      <Field
                        formItemLayout={formItemLayout}
                        name={`var:${idx}:qty`}
                        type="text"
                        component={AInputField}
                        hasFeedback
                        normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                      />
                    </td>
                    <td className="jr-fld-catch">
                      <Button
                        className="mb-0"
                        onClick={() => handleRemoveSpecificRow(idx)}
                        type="primary"
                        shape="circle"
                        icon="delete"
                        size={"large"}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Button
            block
            onClick={() => handleAddRow()}
            className="gx-btn-secondary gx-pulse-effect"
          >
            <Icon type="plus" style={{ verticalAlign: "middle" }} />
            Add Combinations
          </Button>
        </Card>
      );
    } else if (isEnableProductVariable) {
      return (
        <Card style={{ backgroundColor: "#fafafa" }}>
          <div className="" style={{ overflow: "auto" }}>
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <tr>
                  {variations &&
                    variations.map((variation, i) => {
                      return (
                        <th className="text-center" key={i}>
                          {variation.label}
                        </th>
                      );
                    })}
                  <th className="text-center"> Price({DEFAULT_CURRENCY}) </th>
                  <th className="text-center"> Availability </th>
                  <th />
                </tr>
              </thead>
            </table>
          </div>

          <Button
            block
            onClick={this.warningHandle}
            className="gx-btn-secondary gx-pulse-effect"
          >
            <Icon type="plus" style={{ verticalAlign: "middle" }} /> Add
            Combinations
          </Button>
        </Card>
      );
    } else {
      return null;
    }
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ProductVariationsTable = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(ProductVariationsTable);

// You have to connect() to any reducers that you wish to connect to yourself
ProductVariationsTable = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(ProductVariationsTable);

export default ProductVariationsTable;
//399
