import { Col, Row } from "antd";
import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { IS_DOMESTIC_MODE } from "../../../../constants";

function BannerCarousel({ data }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="gx-container">
      <Row>
        <Col span={24} className="">
          <div
            className="gx-card mb-2"
            style={{
              overflow: "hidden",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
          >
            {!IS_DOMESTIC_MODE ? (
              <Carousel
                fade={false}
                activeIndex={index}
                onSelect={handleSelect}
                style={{ borderTop: "1px solid rgba(0, 0, 0, 0.048)" }}
              >
                <Carousel.Item>
                  <img
                    className="d-block mx-auto"
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new.jpg`)}
                    alt="Moto 360"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block mx-auto"
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-2.jpg`)}
                    alt="Moto 360"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block mx-auto"
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-3.jpg`)}
                    alt="Moto 360"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block mx-auto"
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-4.jpg`)}
                    alt="Moto 360"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block mx-auto"
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new-5.jpg`)}
                    alt="Moto 360"
                  />
                </Carousel.Item>
              </Carousel>
            ) : (
              <Carousel
                fade={false}
                activeIndex={index}
                onSelect={handleSelect}
                style={{ borderTop: "1px solid rgba(0, 0, 0, 0.048)" }}
              >
                <Carousel.Item>
                  <img
                    className="d-block mx-auto"
                    src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel-new.jpg`)}
                    alt="Moto 360"
                  />
                </Carousel.Item>
              </Carousel>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BannerCarousel;
